import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  useDeleteSemiAutomationOneTimeScheduleTimeMutation,
  useExcludeSemiAutomationScheduleMutation,
  useLazyViewSemiAutomationOneTimeSchedulesByDateQuery,
  useLazyViewSemiAutomationOneTimeSchedulesBySmartDeviceQuery,
} from "../../../../redux/api/temp-user/tempUserAPI";
import IExcludeSemiAutomationScheduleRequestDTO from "../../../../shared/oversight-core/dtos/request-dtos/exclude-semi-automation-schedule-request-dto";
import IViewSemiAutomationOneTimeSchedulesByDateResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-one-time-schedules-by-date-response-dto";
import IViewSemiAutomationOneTimeSchedulesBySmartDeviceResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-one-time-schedules-by-smart-device-response-dto";
import { EDeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { ERepetitionMode } from "../../../../shared/oversight-core/enums/repetition-mode";
import { IPowerConsumerView } from "../../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import { IExtendedSemiAutomationOneTimeSchedulesView } from "../../../../shared/oversight-core/interfaces/extended-semi-automation-one-time-schedules-view";
import { IOneTimeScheduleDates } from "../../../../shared/oversight-core/interfaces/one-time-schedule-dates";
import ConfirmationModal from "../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatDate } from "../../../../shared/oversight-core/utils/date-utils";
import { offsetToString } from "../../../../shared/oversight-core/utils/offsetToString";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";
import ScheduleUsageModal from "../../breezeLink-temp-user/schedule-usage-modal/schedule-usage-modal";
import styles from "./breezeLink-schedule-usage.module.scss";

interface IProps {
  powerConsumer: IPowerConsumerView;
  selectedSpace: ISpaceView;
  isConnectedAcController: boolean;
}

const BreezeLinkScheduleUsage = (props: IProps) => {
  const { powerConsumer, selectedSpace, isConnectedAcController } = props;

  const [showScheduleUsageModal, setShowScheduleUsageModal] = useState(false);
  const [scheduledList, setScheduledList] = useState<
    IExtendedSemiAutomationOneTimeSchedulesView[]
  >([]);
  const [scheduledListDateRange, setScheduledListDateRange] = useState<
    IExtendedSemiAutomationOneTimeSchedulesView[]
  >([]);
  const [updateCurrentState, setUpdateCurrentState] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [oneTimeSchedule, setOneTimeSchedule] =
    useState<IOneTimeScheduleDates>();

  const [
    triggerSemiAutomationOneTimeSchedulesByDate,
    { isFetching: isFetchingSemiAutomationOneTimeSchedulesByDate },
  ] = useLazyViewSemiAutomationOneTimeSchedulesByDateQuery();
  const [triggerSemiAutomationOneTimeSchedulesBySmartDevice] =
    useLazyViewSemiAutomationOneTimeSchedulesBySmartDeviceQuery();
  const [
    excludeSemiAutomationSchedule,
    { isLoading: isLoadingExcludeSemiAutomationSchedule },
  ] = useExcludeSemiAutomationScheduleMutation();
  const [
    deleteOneTimeScheduleTime,
    { isLoading: isLoadingDeleteOneTimeScheduleTime },
  ] = useDeleteSemiAutomationOneTimeScheduleTimeMutation();

  useEffect(() => {
    if (selectedSpace) {
      triggerSemiAutomationOneTimeSchedulesByDate({
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.id,
        startDate: formatDate(new Date()),
      })
        .unwrap()
        .then((res: IViewSemiAutomationOneTimeSchedulesByDateResponseDTO) => {
          const filterSchedules = res.shallowSemiAutomatedScheduleViews
            .filter((sh) =>
              sh.powerConsumers.some((pc) => pc.id === powerConsumer.id)
            )
            .sort((a, b) => {
              const dateA = new Date(a.oneTimeScheduleDates[0].atDate);
              const dateB = new Date(b.oneTimeScheduleDates[0].atDate);
              return dateA.getTime() - dateB.getTime();
            });

          setScheduledList(filterSchedules);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    triggerSemiAutomationOneTimeSchedulesByDate,
    selectedSpace,
    powerConsumer,
    updateCurrentState,
  ]);

  useEffect(() => {
    if (selectedSpace && selectedSpace.fromDate) {
      triggerSemiAutomationOneTimeSchedulesBySmartDevice({
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.id,
        smartDeviceId:
          selectedSpace.smartDevices.find(
            (sm) => sm.linkedPowerConsumer?.id === powerConsumer.id
          )?.id || "",
        year: new Date(selectedSpace.fromDate).getFullYear(),
        month: new Date(selectedSpace.fromDate).getMonth() + 1,
        day: new Date(selectedSpace.fromDate).getDate(),
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then(
          (
            res: IViewSemiAutomationOneTimeSchedulesBySmartDeviceResponseDTO
          ) => {
            const filterSchedules = res.shallowSemiAutomatedScheduleViews
              .filter((sh) =>
                sh.powerConsumers.some((pc) => pc.id === powerConsumer.id)
              )
              .sort((a, b) => {
                const dateA = new Date(a.oneTimeScheduleDates[0].atDate);
                const dateB = new Date(b.oneTimeScheduleDates[0].atDate);
                return dateA.getTime() - dateB.getTime();
              });

            setScheduledListDateRange(filterSchedules);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    triggerSemiAutomationOneTimeSchedulesBySmartDevice,
    selectedSpace,
    powerConsumer,
    updateCurrentState,
  ]);

  const excludeSchedule = async (
    excludeSemiAutoSchedule: IExcludeSemiAutomationScheduleRequestDTO
  ) => {
    await excludeSemiAutomationSchedule(excludeSemiAutoSchedule)
      .then(() => {
        showSuccessMessage("Schedule Deleted Successfully");
        setUpdateCurrentState((ps) => !ps);
        setShowDeleteConfirmModal(false);
      })
      .catch(() => {
        showErrorMessage("Schedule Deleted Unsuccessfully");
      });
  };

  const deleteSchedule = (
    clusterId: string,
    spaceId: string,
    semiAutomatedScheduleId: string,
    schedulingStateId: string
  ) => {
    deleteOneTimeScheduleTime({
      spaceClusterId: clusterId,
      subRootSpaceId: spaceId,
      semiAutomatedScheduleId: semiAutomatedScheduleId,
      schedulingStateId: schedulingStateId,
    })
      .then(() => {
        showSuccessMessage("Schedule Deleted Successfully");
        setUpdateCurrentState((ps) => !ps);
        setShowDeleteConfirmModal(false);
      })
      .catch(() => {
        showErrorMessage("Schedule Deleted Unsuccessfully");
      });
  };

  return (
    <>
      <Row>
        <Col className={`${styles.scheduleUsageContainer} pb-2`}>
          <Row>
            <Col
              className={`${styles.scheduleUsage} px-3 py-2 cursor-pointer`}
              onClick={() => setShowScheduleUsageModal(true)}
            >
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <MaterialIcon icon="autoplay" color="#69768B" />
                </Col>
                <Col className="text-light font-weight-500 font-size-12">
                  Schedule Usage
                </Col>
              </Row>
            </Col>
          </Row>
          {scheduledList.length > 0 ? (
            scheduledList[0].oneTimeScheduleDates.map((ots) => {
              return (
                <Row
                  key={ots.id}
                  className="align-items-center justify-content-center justify-content-sm-between mt-2 px-1"
                >
                  <Col className="col-auto text-light font-weight-500 font-size-12">
                    <Row>
                      <Col className="col-auto pe-0">
                        {scheduledList[0].title}
                      </Col>
                      <Col className="col-auto px-1">
                        <MaterialIcon
                          icon="keyboard_double_arrow_right"
                          color="#69768B"
                          size={18}
                        />
                      </Col>
                      <Col className="col-auto ps-0">
                        {moment(ots.atDate).format("hh:mm A")}
                        &nbsp;|&nbsp;{ots.powerState}
                        {ots.temperature ? (
                          ots.powerState === EDeviceStatus.ON && (
                            <>
                              &nbsp;|&nbsp;
                              {ots.temperature} &#8451;
                            </>
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-sm-auto">
                    <Row
                      className="align-items-center justify-content-center"
                      onClick={() => {
                        setShowDeleteConfirmModal(true);
                        setOneTimeSchedule(ots);
                      }}
                    >
                      <Col className="col-auto pe-0">
                        <MaterialIcon
                          icon="close_small"
                          color="#AA1821"
                          size={16}
                          className="cursor-pointer"
                        />
                      </Col>
                      <Col className="col-auto text-danger font-weight-400 font-size-12 ps-1 cursor-pointer">
                        {scheduledList[0].repetitionMode ===
                        ERepetitionMode.DO_NOT_REPEAT
                          ? `Remove`
                          : `Remove Today`}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })
          ) : (
            <>
              <Row className="mt-2">
                <Col className="text-center text-light font-weight-400 font-size-12">
                  No schedules for today
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <ScheduleUsageModal
        show={showScheduleUsageModal}
        onClose={() => setShowScheduleUsageModal(false)}
        onCancel={() => setShowScheduleUsageModal(false)}
        powerConsumer={powerConsumer}
        selectedSpace={selectedSpace}
        isConnectedAcController={isConnectedAcController}
        setUpdateCurrentState={setUpdateCurrentState}
        scheduledList={scheduledList}
        scheduledListDateRange={scheduledListDateRange}
      />
      <ConfirmationModal
        show={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        onCancel={() => setShowDeleteConfirmModal(false)}
        title="Remove Schedule"
        description="Are you sure you want to remove this schedule?"
        onConfirm={() => {
          if (selectedSpace && oneTimeSchedule) {
            if (
              scheduledList[0].repetitionMode === ERepetitionMode.DO_NOT_REPEAT
            ) {
              deleteSchedule(
                selectedSpace.clusterId,
                selectedSpace.id,
                scheduledList[0].id,
                oneTimeSchedule.id
              );
            } else {
              excludeSchedule({
                semiAutomatedScheduleId: scheduledList[0].id,
                spaceClusterId: selectedSpace.clusterId,
                subRootSpaceId: selectedSpace.id,
                scheduleType: "ONE_TIME_SEMI_AUTOMATED",
                schedulingStateId: oneTimeSchedule.id,
                from: formatDate(new Date(oneTimeSchedule.atDate)),
                to: null,
                zoneOffset: offsetToString(moment().utcOffset()),
              });
            }
          }
        }}
        isLoading={
          isLoadingExcludeSemiAutomationSchedule ||
          isLoadingDeleteOneTimeScheduleTime
        }
      />
      <SpinnerModal show={isFetchingSemiAutomationOneTimeSchedulesByDate} />
    </>
  );
};

export default BreezeLinkScheduleUsage;
