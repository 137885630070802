import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useAddSemiAutomationOneTimeScheduleMutation,
  useDeleteSemiAutomationOneTimeScheduleMutation,
  useUpdateSemiAutomationOneTimeScheduleMutation,
} from "../../../../redux/api/temp-user/tempUserAPI";
import AcSchedule from "../../../../shared/components/ac-schedule/ac-schedule";
import IAddSemiAutomationOneTimeScheduleRequestDTO from "../../../../shared/oversight-core/dtos/request-dtos/add-semi-automation-one-time-schedule-request-dto";
import { EConnectionStatus } from "../../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { ERepetitionMode } from "../../../../shared/oversight-core/enums/repetition-mode";
import { IPowerConsumerView } from "../../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import { IExtendedSemiAutomationOneTimeSchedulesView } from "../../../../shared/oversight-core/interfaces/extended-semi-automation-one-time-schedules-view";
import { IHttpError } from "../../../../shared/oversight-core/interfaces/http-errror";
import { ISchedulingDateView } from "../../../../shared/oversight-core/interfaces/scheduling-date.view";
import { ISchedulingState } from "../../../../shared/oversight-core/interfaces/scheduling-state";
import ConfirmationModal from "../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import ActiveInactiveIndicator from "../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import IconButton from "../../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { formatDate } from "../../../../shared/oversight-core/utils/date-utils";
import { findRepetition } from "../../../../shared/oversight-core/utils/find-repetition";
import findIcon from "../../../../shared/oversight-core/utils/findIcon";
import { generateRepetitionOptions } from "../../../../shared/oversight-core/utils/generate-repetition-options";
import {
  formatOneTimeSlot,
  formatTime,
} from "../../../../shared/oversight-core/utils/time-utils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  show: boolean;
  powerConsumer: IPowerConsumerView;
  selectedSpace: ISpaceView;
  isConnectedAcController: boolean;
  setUpdateCurrentState: React.Dispatch<React.SetStateAction<boolean>>;
  scheduledList: IExtendedSemiAutomationOneTimeSchedulesView[];
  scheduledListDateRange: IExtendedSemiAutomationOneTimeSchedulesView[];
}

const initialSchedule: ISchedulingDateView = {
  id: 0,
  scheduleId: "",
  atDate: "8:30 AM",
  powerState: EDeviceStatus.ON,
  temperature: 24,
};

interface IFormInput
  extends Omit<
    IAddSemiAutomationOneTimeScheduleRequestDTO,
    "spaceClusterId" | "subRootSpaceId"
  > {
  schedulingStates: ISchedulingState[];
}

const ScheduleUsageModal = (props: IProps) => {
  const {
    show,
    powerConsumer,
    selectedSpace,
    isConnectedAcController,
    setUpdateCurrentState,
    scheduledList,
    scheduledListDateRange,
    ...rest
  } = props;

  // const selectedDays = [
  //   new Date(2024, 10, 4),
  //   new Date(2024, 10, 5),
  //   new Date(2024, 10, 6),
  // ];
  // const disabledDays = [new Date(2024, 10, 8), new Date(2024, 10, 9)];

  const [schedules, setSchedules] = useState<ISchedulingDateView[]>([
    { ...initialSchedule },
  ]);
  const [error, setError] = useState<string>("");
  const [selectedRepetition, setSelectedRepetition] = useState<Option>(
    generateRepetitionOptions(new Date())[0]
  );
  const [showExpireDate, setShowExpireDate] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [
    addSemiAutomationOneTimeSchedule,
    { isLoading: isLoadingAddSemiAutomationOneTimeSchedule },
  ] = useAddSemiAutomationOneTimeScheduleMutation();
  const [updateSemiAutomationOneTimeSchedule] =
    useUpdateSemiAutomationOneTimeScheduleMutation();
  const [deleteOneTimeSchedule, { isLoading: isLoadingDeleteOneTimeSchedule }] =
    useDeleteSemiAutomationOneTimeScheduleMutation();

  const { handleSubmit } = useForm<IFormInput>();

  useEffect(() => {
    if (selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT) {
      setShowExpireDate(false);
    } else {
      setShowExpireDate(true);
    }
  }, [selectedRepetition]);

  useEffect(() => {
    if (scheduledListDateRange.length > 0) {
      setSchedules(
        scheduledListDateRange[0].oneTimeScheduleDates?.map((ots, index) => {
          return {
            id: index,
            scheduleId: ots.id,
            atDate: formatTime(new Date(ots.atDate)),
            powerState: ots.powerState,
            temperature: ots.temperature as number,
          };
        })
      );
      setSelectedRepetition(
        findRepetition(
          scheduledListDateRange[0].repetitionMode as ERepetitionMode,
          generateRepetitionOptions(
            new Date(scheduledListDateRange[0].startDate)
          )
        )
      );

      if (scheduledListDateRange[0].expireDate) {
        setSelectedEndDate(new Date(scheduledListDateRange[0].expireDate));
      }
    } else if (scheduledList.length > 0) {
      setSchedules(
        scheduledList[0].oneTimeScheduleDates.map((ots, index) => {
          return {
            id: index,
            scheduleId: ots.id,
            atDate: formatTime(new Date(ots.atDate)),
            powerState: ots.powerState,
            temperature: ots.temperature as number,
          };
        })
      );
      setSelectedRepetition(
        findRepetition(
          scheduledList[0].repetitionMode as ERepetitionMode,
          generateRepetitionOptions(new Date(scheduledList[0].startDate))
        )
      );

      if (scheduledList[0].expireDate) {
        setSelectedEndDate(new Date(scheduledList[0].expireDate));
      }
    } else {
      setSchedules([{ ...initialSchedule }]);
      setSelectedRepetition(generateRepetitionOptions(new Date())[0]);
    }

    setError("");
  }, [scheduledList, show]);

  const parseTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const isAM = time.includes("AM");
    let adjustedHours = parseInt(hours);
    if (!isAM && adjustedHours !== 12) adjustedHours += 12;
    if (isAM && adjustedHours === 12) adjustedHours = 0;
    return new Date(2000, 0, 1, adjustedHours, parseInt(minutes.split(" ")[0]));
  };

  const handleAddSchedule = () => {
    if (schedules.length >= 2) {
      const lastSchedule = schedules[schedules.length - 1];
      const secondLastSchedule = schedules[schedules.length - 2];

      const lastTime = parseTime(lastSchedule.atDate);
      const secondLastTime = parseTime(secondLastSchedule.atDate);

      if (lastTime <= secondLastTime) {
        setError("The scheduled times are not in the correct order.");
        return;
      }
      setError("");
    }

    setSchedules((prevSchedules) => {
      const lastId = prevSchedules.length
        ? prevSchedules[prevSchedules.length - 1].id
        : 0;
      return [...prevSchedules, { ...initialSchedule, id: lastId + 1 }];
    });
  };

  const handleRemoveSchedule = (id: number) => {
    setSchedules((prevSchedules) =>
      prevSchedules
        .filter((schedule) => schedule.id !== id)
        .map((schedule, index) => ({ ...schedule, id: index + 1 }))
    );
    setError("");
  };

  const handleTimeChange = (id: number, time: string) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, atDate: time } : schedule
      )
    );
  };

  const handleToggleChange = (id: number, powerState: EDeviceStatus) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, powerState } : schedule
      )
    );
  };

  const handleTemperatureChange = (id: number, temperature: number) => {
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, temperature } : schedule
      )
    );
  };

  const onSubmit = async () => {
    // if (schedules && schedules.length > 0) {
    //   const firstSchedule = schedules[0];
    //   const formattedTime = formatOneTimeSlot(new Date(), firstSchedule.atDate);
    //   const formattedDate = new Date(formattedTime);

    //   if (formattedDate < new Date()) {
    //     showErrorMessage(
    //       "Selected time should be greater than the current time"
    //     );
    //     return;
    //   }
    // }

    // if (schedules.length >= 2) {
    //   const lastSchedule = schedules[schedules.length - 1];
    //   const secondLastSchedule = schedules[schedules.length - 2];

    //   const lastTime = parseTime(lastSchedule.atDate);
    //   const secondLastTime = parseTime(secondLastSchedule.atDate);

    //   if (lastTime <= secondLastTime) {
    //     setError("The schedule times are not in the correct order");
    //     return;
    //   }
    //   setError("");
    // }

    // for (let i = 0; i < schedules.length - 1; i++) {
    //   const currentTime = parseTime(schedules[i].atDate);
    //   const nextTime = parseTime(schedules[i + 1].atDate);

    //   if (currentTime >= nextTime) {
    //     setError("The schedule times are not in the correct order");
    //     return;
    //   }
    // }

    if (
      new Date(selectedEndDate) <= new Date() &&
      !(selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT)
    ) {
      setEndDateError("Expire date should be later than start date");
      return;
    }

    const lastScheduledTime = formatOneTimeSlot(
      selectedEndDate,
      schedules[schedules.length - 1].atDate
    );
    const reservedEndTime = selectedSpace.toDate
      ? new Date(selectedSpace.toDate)
      : new Date();

    reservedEndTime.setDate(reservedEndTime.getDate() + 1);

    if (lastScheduledTime > reservedEndTime.toISOString()) {
      setError("You can only schedule within your reserved stay period");
      return;
    }

    if (selectedSpace) {
      const newSchedule = {
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.id,
        title: "Scheduled",
        schedulingDate: formatDate(
          moment(selectedStartDate).startOf("day").toDate()
        ),
        schedulingStates: schedules.map((sh) => {
          return {
            atDate: formatOneTimeSlot(selectedStartDate, sh.atDate),
            powerState: sh.powerState,
            temperature: isConnectedAcController ? sh.temperature : null,
          };
        }),
        expireDate:
          selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT
            ? null
            : formatDate(moment(selectedEndDate).startOf("day").toDate()),
        repetitionMode: selectedRepetition?.value as ERepetitionMode,
        selectedPowerConsumerIdentities: [powerConsumer.id],
        isDraft: false,
      };

      if (scheduledListDateRange.length > 0) {
        await updateSemiAutomationOneTimeSchedule({
          ...newSchedule,
          semiAutomatedScheduleId: scheduledListDateRange[0].id,
        })
          .unwrap()
          .then(() => {
            showSuccessMessage("Schedule Updated Successfully");
            props.onClose && props.onClose();
            setUpdateCurrentState((ps) => !ps);
          })
          .catch((error: IHttpError) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              setError(
                "You can only schedule within your reserved stay period"
              );
              return;
            }
            showErrorMessage("Schedule Update Failed");
          });
      } else {
        await addSemiAutomationOneTimeSchedule({
          ...newSchedule,
        })
          .unwrap()
          .then(() => {
            showSuccessMessage("Schedule Added Successfully");
            props.onClose && props.onClose();
            setUpdateCurrentState((ps) => !ps);
          })
          .catch((error: IHttpError) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              setError(
                "You can only schedule within your reserved stay period"
              );
              return;
            }
            showErrorMessage("Schedule Add Failed");
          });
      }
    }
  };

  const deleteSchedule = (
    clusterId: string,
    spaceId: string,
    semiAutomatedScheduleId: string
  ) => {
    deleteOneTimeSchedule({
      spaceClusterId: clusterId,
      subRootSpaceId: spaceId,
      semiAutomatedScheduleId: semiAutomatedScheduleId,
    })
      .then(() => {
        showSuccessMessage("Schedule Deleted Successfully");
        setShowDeleteConfirmModal(false);
        props.onClose && props.onClose();
        setUpdateCurrentState((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Schedule Deleted Unsuccessfully");
      });
  };

  return (
    <ModalContainer
      {...rest}
      title="Schedule Usage"
      show={show}
      confirmButtonText="Save"
      cancelButtonText="Cancel"
      onConfirm={handleSubmit(onSubmit)}
      onClose={() => {
        props.onCancel && props.onCancel();
        setEndDateError("");
        setStartDateError("");
      }}
      onCancel={() => {
        props.onCancel && props.onCancel();
        setEndDateError("");
        setStartDateError("");
      }}
      isLoading={isLoadingAddSemiAutomationOneTimeSchedule}
    >
      <>
        <Row className="align-items-center mx-0">
          <Col>
            <Row>
              <Col
                className={`${powerConsumer.deviceType} p-3 rounded col-auto`}
              >
                <MaterialIcon icon={findIcon(powerConsumer.deviceType)} />
              </Col>
              <Col>
                <Row>
                  <Col
                    className="text-truncate text-dark font-weight-500 font-size-20"
                    style={{ width: "100px" }}
                  >
                    {powerConsumer.name}
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col className="col-auto pe-0">
                    <ActiveInactiveIndicator
                      isActive={
                        powerConsumer.deviceConnectionState ===
                        EConnectionStatus.CONNECTED
                      }
                    />
                  </Col>
                  <Col className="col-auto text-light font-weight-400 font-size-14 ps-2">
                    {powerConsumer.powerUsageInWatt} W
                  </Col>
                  {/* <Col className="col-auto text-light font-weight-400 font-size-14 ps-1">
                Room 1
              </Col> */}
                </Row>
              </Col>
            </Row>
          </Col>
          {scheduledListDateRange.length > 0 && (
            <Col className="col-auto">
              <Row className="align-items-center">
                <Col className="col-auto text-danger font-weight-400 font-size-14 d-none d-md-block">
                  Remove All
                  <br />
                  Schedules
                </Col>
                <Col
                  className="col-auto bg-mercury p-3 rounded-5"
                  onClick={() => setShowDeleteConfirmModal(true)}
                >
                  <MaterialIcon
                    icon="delete"
                    color="#AA1821"
                    size={20}
                    className="cursor-pointer"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col className="text-dark font-weight-400 font-size-16">
            Time&nbsp;:&nbsp;
            <span className="text-light font-weight-400 font-size-14">
              {moment(selectedSpace.fromDate).format("DD MMMM hh:mm A")}
              &nbsp;-&nbsp;
              {moment(selectedSpace.toDate).format("DD MMMM hh:mm A")}
            </span>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AppSelect
              label="Repetition"
              placeholder="Weekly on monday"
              selectedValue={selectedRepetition}
              onChangeOption={(selectedOption) =>
                setSelectedRepetition(selectedOption)
              }
              options={generateRepetitionOptions(new Date())}
              isRequired={true}
              id="schedule-repetition"
            />
            {/* <DaysOfWeek
              selectedDays={selectedDays}
              disabledDays={disabledDays}
            /> */}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label>
              Start date<span style={{ color: "red" }}>*</span>
            </label>
            <AppDatePicker
              dateFormat="dd MMMM yyyy"
              selectedDate={selectedStartDate}
              onChange={(date: Date) => {
                setSelectedStartDate(date);
                setStartDateError("");
              }}
              isInput={true}
              error={startDateError}
            />
          </Col>
        </Row>
        {showExpireDate && (
          <Row className="mt-4">
            <Col>
              <label>
                Expire date<span style={{ color: "red" }}>*</span>
              </label>
              <AppDatePicker
                dateFormat="dd MMMM yyyy"
                selectedDate={selectedEndDate}
                onChange={(date: Date) => {
                  setSelectedEndDate(date);
                  setEndDateError("");
                }}
                isInput={true}
                error={endDateError}
              />
            </Col>
          </Row>
        )}
        <div className="mt-4">
          {schedules?.map((schedule) => (
            <Row key={schedule.id} className="mt-3">
              <Col>
                <AcSchedule
                  schedule={schedule}
                  onRemove={() => handleRemoveSchedule(schedule.id)}
                  onTimeChange={(time) =>
                    handleTimeChange(schedule.id, time.value)
                  }
                  onToggleChange={(powerState) =>
                    handleToggleChange(schedule.id, powerState)
                  }
                  onTemperatureChange={(temperature) => {
                    if (14 < temperature && temperature < 36) {
                      handleTemperatureChange(schedule.id, temperature);
                    }
                  }}
                  scheduleCounter={schedules.length}
                  isConnectedAcController={isConnectedAcController}
                />
              </Col>
            </Row>
          ))}
          {error && (
            <Row className="mt-1">
              <Col className="text-danger font-size-14 font-weight-400">
                {error}
              </Col>
            </Row>
          )}
        </div>
        <Row className="mt-4">
          <Col className="col-auto">
            <IconButton
              icon="add"
              text="Add Time Slot"
              onClick={handleAddSchedule}
            />
          </Col>
        </Row>
        <ConfirmationModal
          show={showDeleteConfirmModal}
          onClose={() => setShowDeleteConfirmModal(false)}
          onCancel={() => setShowDeleteConfirmModal(false)}
          title="Remove Schedule"
          description="Are you sure you want to remove this schedule?"
          onConfirm={() => {
            if (selectedSpace) {
              deleteSchedule(
                selectedSpace.clusterId,
                selectedSpace.id,
                scheduledListDateRange[0].id
              );
            }
          }}
          isLoading={isLoadingDeleteOneTimeSchedule}
        />
      </>
    </ModalContainer>
  );
};

export default ScheduleUsageModal;
